nav .brand-logo {
    margin-left: 16px;
}

.card-content .value-container {
    align-items: first baseline;
}

.card-content .value-current {
    display: inline-block;
}

.card-content .value-difference {
    display: inline-block;
    margin-left: 16px;
}

.separator {
    padding: 0.75rem;
    margin-top: 1rem;
    margin-bottom: 0;
}

.separator h4 {
    margin: 0;
}