ul.browser-default {
    padding-left: inherit !important;
    list-style-type: inherit !important;
}

li.browser-default {
    list-style-type: inherit !important;
}

.primary {
    background-color: #0085FF !important;
}

.primary-text {
    color: #0085FF !important;
}

img.primary {
    filter: invert(50%) sepia(77%) saturate(5983%) hue-rotate(195deg) brightness(104%) contrast(105%);
}

.btn-floating.primary {
    background-color: #0085FF;
}

.secondary {
    color: #00B2FF;
}

.secondary-text {
    color: #00B2FF;
}

.btn-floating.secondary {
    background-color: #00B2FF;
}

.review-star.small {
    color: #FFE600;
    letter-spacing: -1rem;
    font-size: x-large;
}

div.row.cascade {
    margin-bottom: 0;
}

div.row.cascade.first {
    margin-top: 2rem;
}

div.page-center {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin-left: 0;
    margin-right: 0;
}

a.clickable {
    cursor: pointer;
}

th.clickable {
    cursor: pointer;
}

@font-face {
    font-family: 'Material Symbols Rounded';
    font-style: normal;
    font-weight: 100 700;
    src: url(https://fonts.gstatic.com/s/materialsymbolsrounded/v33/sykg-zNym6YjUruM-QrEh7-nyTnjDwKNJ_190FjzaqkNCeE.woff2) format('woff2');
}

.material-symbols-rounded {
    font-family: 'Material Symbols Rounded';
    font-weight: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    font-variation-settings:
            'FILL' 0,
            'wght' 400,
            'GRAD' 0,
            'opsz' 48
}